import React from "react";
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import * as APIt from "../../API";
import
  {
    getCachedSiteCamerasV1,
    listAccessControlDevicesV1,
    listDeviceLinksForSiteV1,
    listDeviceLinkEventsForDeviceLinkV1,
    listEventsV1,
    listSiteCamerasV1,
    listSystemsForSiteV1,
  } from '../../graphql/queries';
import DeviceLinksTablePanel from './TablePanel';
import { ForceAwakensStateInterface } from "src/stores/app";
import { State } from "@hookstate/core";
import { REGIONS } from "src/common/constants";

export interface DeviceLinksMainContentPropsInterface {
  forceAwakensState: State<ForceAwakensStateInterface>;
}

export interface RefreshEventOptionsCallbackInterface {
  (deviceType: string): void;
}
export interface RefreshDeviceOptionsCallBackInterface {
  (): void;
}
export interface RefreshCameraOptionsCallBackInterface {
  (): void;
}
export interface RefreshAllCallBackInterface {
  (): void;
}
export interface SelectOptionInterface {
  label: string;
  value: string;
}
interface DeviceLinksMainContentStateInterface {
  acpDeviceOptions: SelectOptionInterface[];
  acpDeviceOptionsLoading: boolean;
  cameraDeviceOptions: SelectOptionInterface[];
  cameraDeviceOptionsLoading: boolean;
  deviceLinks: APIt.DeviceLink[];
  eventOptions: SelectOptionInterface[];
  isTableLoading: boolean;
  systemOptions: SelectOptionInterface[];
  systems: APIt.System[];
}
export default class DeviceLinksMainContent extends React.Component
  <DeviceLinksMainContentPropsInterface, DeviceLinksMainContentStateInterface>
{
  private _isMounted: boolean;

  constructor(props: DeviceLinksMainContentPropsInterface) {
    super(props);
    this._isMounted = false;
    this.state = {
      deviceLinks: [],
      acpDeviceOptions: [],
      acpDeviceOptionsLoading: false,
      cameraDeviceOptions: [],
      cameraDeviceOptionsLoading: false,
      isTableLoading: false,
      eventOptions: [],
      systemOptions: [],
      systems: [],
    };
  };

  refreshAll = async () => {
    this.setState(
      {
        deviceLinks: [],
        isTableLoading: true,
      });
    await Promise.all(
      [
        this.refreshSystemOptions().then(() => { this.refreshCameraOptions(); }),
        this.refreshDeviceOptions()
      ]);
    await this.refreshDeviceLinks();
    this.setState(
      {
        isTableLoading: false,
      });
  };

  refreshDeviceLinks = async (): Promise<void> => {
    const siteCode = this.props.forceAwakensState.get().selectedSite?.siteCode;
    if (!siteCode || !this._isMounted) return;
    
    // Fetching the Device Link Events for a particular device based on device Id
    const loadDeviceLinkEvents = async (device_id: string) => {
      try {
        const response = await API.graphql(graphqlOperation(
          listDeviceLinkEventsForDeviceLinkV1,
          { device_link_id: device_id }
        )) as GraphQLResult<APIt.ListDeviceLinkEventsForDeviceLinkV1Query>;
        const event_names = response.data?.listDeviceLinkEventsForDeviceLinkV1 as APIt.DeviceLinkEvent[];
        const links: string[] = event_names.map(event_name => event_name.event_name + ",");
        return links; // returning a string array of linked events
      } catch(e) {
        console.error(`exception is ${JSON.stringify(e)}`);
      }
      return []; //return empty array if no events are linked
    };

    const findDevice = (parentDeviceId: number, childDeviceId: number, subchildDeviceName: number) => {
      const foundDevice = this.state.acpDeviceOptions.find((d) => {
        const deviceParentDeviceId = d.value.split('-')[1];
        const deviceChildDeviceIdValue = d.value.split('-')[2];
        const deviceSubchildDeviceIdValue = d.value.split('-')[3];
        const result = (parentDeviceId == parseInt(deviceParentDeviceId)
          && childDeviceId == parseInt(deviceChildDeviceIdValue)
          && subchildDeviceName == parseInt(deviceSubchildDeviceIdValue));
        return result;
      });
      return(foundDevice);
    };
    try {
      const response = await API.graphql(graphqlOperation(
        listDeviceLinksForSiteV1,
        {
          segment_name: siteCode
        })) as GraphQLResult<APIt.ListDeviceLinksForSiteV1Query>;
      const deviceLinks = response.data?.listDeviceLinksForSiteV1 as APIt.DeviceLink[];
      // get site devices and merge into listDeviceLinksForSite.data. response
      const updatedDeviceLinks = await Promise.all(deviceLinks.map(async (dl) => {
        const foundDevice = findDevice(dl.acd_parent_device_id, dl.acd_child_device_id, dl.acd_subchild_device_id);
        const device_link_events = await loadDeviceLinkEvents(dl.id); // Await here
        return {
          ...dl,
          acd_device_name: foundDevice?.label || dl.acd_device_name,
          acd_device_type: foundDevice?.value.split('-')[0] || dl.acd_device_type,
          event_links: device_link_events
        };
      }));
      this.setState({
        deviceLinks: updatedDeviceLinks
      });
    } catch (error) {
      console.error(`refreshDeviceLinks(): error is ${JSON.stringify(error)}`);
    }
  };

  refreshDeviceOptions = async (): Promise<void> => {
    const siteCode = this.props.forceAwakensState.get().selectedSite?.siteCode;
    if (!siteCode || !this._isMounted) return;
    let stage = 'gamma';
    this.setState(
      {
        acpDeviceOptions: [],
        acpDeviceOptionsLoading: true
      });
    try {
      const acpDeviceOptions = [];
      for (let region of REGIONS) {
        const listAccessControlDevicesResponse = await API.graphql(graphqlOperation(
          listAccessControlDevicesV1,
          {
            path: `/FlexQuery/ForceAwakens_SegmentFilter_ChildDevices_V3/region/${region}/stage/${stage === 'test' ? 'BETA' : stage.toUpperCase()}/?SiteCode=${siteCode}&OutputFormat=json`
          })) as GraphQLResult<APIt.ListAccessControlDevicesV1Query>;
        const accessControlDevices = listAccessControlDevicesResponse.data?.listAccessControlDevicesV1 as APIt.AccessControlDevice[];
        acpDeviceOptions.push(...accessControlDevices.map((el) => { return (
          {
            label: el.DeviceName!,
            value: `${el.Device_Type}-${el.Parent_DeviceID!.toString()}-${el.Child_DeviceID}-${el.Subchild_DeviceID}`
          });
        }));
      }
      this.setState(
        {
          acpDeviceOptions: acpDeviceOptions
        });
    } catch(error) {
      console.error(`refreshDeviceOptions(): error is ${JSON.stringify(error)}`);
    }
    this._isMounted && this.setState({
      acpDeviceOptionsLoading: false
    });
  };

  refreshCameraOptions = async (): Promise<void> => {
    if (!this._isMounted) return;
    this.setState({
      cameraDeviceOptions: [],
      cameraDeviceOptionsLoading: true
    });
    for (let system of this.state.systems) {
      try {
        let cameras: APIt.Camera[] = [];
        try {
          const listCamerasResponse = await API.graphql(graphqlOperation(listSiteCamerasV1,
            {
              authMode: system.auth_mode,
              cmdSrvcEndpoint: `${system.srvr_vpc_endpoint}:${system.srvr_cmd_srvc_port}`,
              cmdSrvcPath: system.srvr_cmd_srvc_path,
              cmdSrvcPort: system.srvr_cmd_srvc_port,
              siteCode: system.segment_name
            })) as GraphQLResult<APIt.ListSiteCamerasV1Query>;
          cameras = listCamerasResponse.data?.listSiteCamerasV1 as APIt.Camera[];
        } catch(error) {
          console.error(`refreshCameraOptions(): listSiteCameras error is ${error} JSON.stringify(error) is ${JSON.stringify(error)}`);
        }
        if (cameras.length == 0) {
          let index = 1;
          while (true) {
            let siteCode = `${system.segment_name}-${index++}`;
            const getCachedCamerasResponse = await API.graphql(graphqlOperation(
              getCachedSiteCamerasV1,
              {
                siteCode: siteCode
              })) as GraphQLResult<APIt.GetCachedSiteCamerasV1Query>;
            if ((!getCachedCamerasResponse.data?.getCachedSiteCamerasV1?.cameras)
            || (getCachedCamerasResponse.data?.getCachedSiteCamerasV1?.cameras as APIt.Camera[]).length == 0) {
              break;
            }
            cameras.push(...getCachedCamerasResponse.data?.getCachedSiteCamerasV1?.cameras as APIt.Camera[]);
          }
        }
        const cameraDeviceOptions = cameras.map((el) => { return ({ label: el.name!, value: el.systemIdentifier! }); });
        this._isMounted && this.setState(
          {
            cameraDeviceOptions: [...this.state.cameraDeviceOptions, ...cameraDeviceOptions.sort()]
          });
      } catch(error) {
        console.error(`refreshCameraOptions(): error is ${error} JSON.stringify(error) is ${JSON.stringify(error)}`);
      }
    }
    this._isMounted && this.setState({
      cameraDeviceOptionsLoading: false
    });
  };

  refreshEventOptions = async (deviceType: string): Promise<void> => {
    if (!this._isMounted) return;
    this.setState(
      {
        eventOptions: []
      });
    try {
      const response = await API.graphql(graphqlOperation(listEventsV1)) as GraphQLResult<APIt.ListEventsV1Query>;
      let events = response.data?.listEventsV1 as APIt.Event[];
      events = events.filter(el => {
        switch(el.name) {
          case 'Alarm Active':
            if (deviceType === 'alarm_panel_input' || deviceType === 'card_reader_aux_input_1' || deviceType === 'card_reader_aux_input_2')
              return true;
            else
              return false;
          default:
            if (deviceType !== 'alarm_panel_input' && deviceType !== 'card_reader_aux_input_1' && deviceType !== 'card_reader_aux_input_2')
              return true;
            else
              return false;
        }
      });
      const eventOptions = events.map((el) => { return ({ label: el.name!, value: el.id! }); });
      this._isMounted && this.setState(
        {
          eventOptions: eventOptions.sort()
        });
    } catch(error) {
      console.error(`refreshEventOptions(): error is ${JSON.stringify(error)}`);
    }
  };

  refreshSystemOptions = async (): Promise<void> => {
    const siteCode = this.props.forceAwakensState.get().selectedSite?.siteCode;
    if (!siteCode || !this._isMounted) return;
    this.setState(
      {
        systemOptions: [],
        systems: []
      });
    try {
      const response = await API.graphql(graphqlOperation(listSystemsForSiteV1,
        {
          segment_name: siteCode
        })) as GraphQLResult<APIt.ListSystemsForSiteV1Query>;
      const systems = response.data?.listSystemsForSiteV1 as APIt.System[];
      const systemOptions = systems.map((el) => { return ({ label: el.name!, value: el.id! }); });
      this._isMounted && this.setState(
        {
          systemOptions: systemOptions.sort(),
          systems: systems
        });
      } catch(error) {
        console.error(`refreshSystemOptions(): error is ${JSON.stringify(error)}`);
        throw error;
      }
  };

  componentDidMount() {
    this._isMounted = true;
    this.refreshAll();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps: Readonly<DeviceLinksMainContentPropsInterface>, nextContext: any): void {
    this.refreshAll(); 
  }

  render() {
    return (
      <DeviceLinksTablePanel
        acpDeviceOptions={this.state.acpDeviceOptions}
        acpDeviceOptionsLoading={this.state.acpDeviceOptionsLoading}
        cameraDeviceOptions={this.state.cameraDeviceOptions}
        cameraDeviceOptionsLoading={this.state.cameraDeviceOptionsLoading}
        deviceLinks={this.state.deviceLinks}
        forceAwakensState={this.props.forceAwakensState}
        eventOptions={this.state.eventOptions}
        refreshEventOptionsCallback={this.refreshEventOptions}
        isTableLoading={this.state.isTableLoading}
        refreshAllCallback={this.refreshAll}
        refreshDeviceOptionsCallback={this.refreshDeviceOptions}
        refreshCameraOptionsCallback={this.refreshCameraOptions}
        systemOptions={this.state.systemOptions}
      />
    );
  }
}